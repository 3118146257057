import React, { useState, useEffect } from 'react'
import Header from '../Header/header'
import Footer from '../Footer/footer'
import EventListingTextContent from './EventListingComponents/EventListingTextContent';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import Axios from 'axios';
import MoreEventCard from '../EventsPage/EventComponents/MoreEventCard';
import FilterButton from './EventListingComponents/FilterButton';
import "./eventListing.css"


const EventsListing = () => {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [allEvents, setAllEvents] = useState(null);
  const [eventCount, setEventCount] = useState(0);
  const [eventFilters, setEventFilters] =
    useState({
      "regions": [
      ],
      "sports": [
      ],
      "events": [
      ]
    })
  const [allEventFilter, setAllEventFilter] = useState(useState({
    "regions": [
    ],
    "sports": [
    ],
    "events": [
    ]
  }));
  let { eventId } = useParams();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get(`${process.env.REACT_APP_BASE_URL}/events/regions`)
        const eventFilters = response.data;

        if (eventFilters != null) {
          setAllEventFilter(addIdAndValues(eventFilters))
        }

      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    }
    fetchData();
  }, [eventId])


  useEffect(() => {
    const fetchData = async () => {
      try {

        const queryString = createQueryString(eventFilters)

        const response = await Axios.get(`${process.env.REACT_APP_BASE_URL}/events/?${queryString}`)
        const allEvents = response.data.events;
        setAllEvents(allEvents);
        setEventCount(allEvents.length);

      } catch (error) {
        setError(error.message);
        setAllEvents([]);
        setEventCount(0);
      } finally {
        setLoading(false);
      }
    }
    fetchData();

  }, [eventFilters])

  const addIdAndValues = (res) => {
    return {
      regions: res.regions.map((name, index) => ({
        id: index + 1,
        name: name
      })),
      sports: res.sports.map((name, index) => ({
        id: index + 1,
        name: name
      })),
      events: res.events.map((name, index) => ({
        id: index + 1,
        name: name
      }))
    };
  };

  const createQueryString = (filters) => {
    const queryParams = new URLSearchParams();

    const today = new Date();
    const startOfDay = `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}T00:00:00`;

    queryParams.append('event_time_gteq', startOfDay);

    // Add multiple region names, sport types, and event types (without using [])
    if (filters.regions && Array.isArray(filters.regions)) {
      filters.regions.forEach(region => queryParams.append('region_name', region.name));
    }

    if (filters.sports && Array.isArray(filters.sports)) {
      filters.sports.forEach(sport => queryParams.append('sport_type', sport.name));
    }

    if (filters.events && Array.isArray(filters.events)) {
      filters.events.forEach(event => queryParams.append('event_type', event.name));
    }

    return queryParams.toString();
  }


  return (
    <div id='eventListing-container'>
      <Header />

      {/* top text part here */}
      <EventListingTextContent />

      {/* Filter button and Upcoming text here */}
      {loading ?
        <div className="w-full h-[100vh] flex justify-center items-center">
          <ClipLoader size={50} color={"#2D1066"} loading={loading} />
        </div >
        :
        <div>
          <FilterButton count={eventCount} allFilters={allEventFilter} filters={eventFilters} setFilters={setEventFilters} />

          <div id='eventListing-moreEventsCard'>
            <MoreEventCard eventData={allEvents} sliceTill={allEvents.length} showTitle={false} />
          </div>
        </div>




      }
      <Footer />
    </div>
  )
}

export default EventsListing
