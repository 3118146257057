import React from 'react'
import "../About.css"
import collageImage from "../../../Asset/About/collage-image-about.png"
import aboutMobileTop from "../../../Asset/About/about-mobile-top-image.png"
import aboutMobileBottom from "../../../Asset/About/about-mobile-bottom-image.png"
const TopContent = () => {
  return (
    <div className='pt-44 w-full'>

      <div id="heading" className='mb-10 md:mb-24'>
        <h1 className='text-[var(--dark-blue)] text-[2.5rem] md:text-6xl lg:text-7xl  font-ClashGrotesk font-medium text-center'>What is Net Gala?</h1>
      </div>

      {/* For mobile devices only look here */}
      <div className='flex flex-col justify-center items-center md:hidden'>
        {/* upper image */}
        <div className='w-[93%]'>
          <img src={aboutMobileTop} alt="" />
        </div>
        {/* lower div */}
        <div className='w-[93%] -mt-12'>
          <img src={aboutMobileBottom} alt="" />
        </div>
      </div>


      {/* For desktop and medium devices look here */}
      <div className='w-full md:flex md:flex-row justify-center hidden '>
        <img src={collageImage} alt="" />
      </div>

    </div>
  )
}

export default TopContent
