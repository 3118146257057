import React from 'react'
import Header from '../Header/header'
import Footer from '../Footer/footer'
import CurrentEvent from './CurrentEvent/currentEvent';
import './eventspage.css'


const eventspage = () => {
  return (
    <div>
        <Header></Header>

        <CurrentEvent></CurrentEvent>


        <Footer></Footer>
      
    </div>
  )
}

export default eventspage
