import React, { useState, useEffect } from 'react';
import Header from '../Header/header';
import Hero from './Hero/hero';
import Footer from '../Footer/footer';
import HomeAboutUs from './HomeAboutUs/homeAboutUs';
import MobileAppFeature from './MobileAppFeature/mobileAppFeature';
import StayInTouchCard from './StayInTouchCard/stayInTouchCard';
import CustomerOptions from './CustomerOptions/customerOptions';
import Brand from './BrandAndCommunity/brand';
import Community from './BrandAndCommunity/community';
import JoinMembershipModel from './JoinMembershipModel/joinMembershipModel';
import StayInTouchModel from './StayInTouchModel/stayInTouchModel';
import './homepage.css';
import axios from 'axios';

const Homepage = () => {

    const [isMembershipModalOpen, setIsMembershipModalOpen] = useState(false);
    const [isStayInTouchModalOpen, setIsStayInTouchModalOpen] = useState(false);
    const [loader, setLoader] = useState(false);
    const [games, setGames] = useState([]);

    const toggleMembershipModal = () => {
        setIsMembershipModalOpen(!isMembershipModalOpen);
    };

    const toggleStayInToucModal = () => {
        setIsStayInTouchModalOpen(!isStayInTouchModalOpen);
        console.log(isStayInTouchModalOpen);
    };

    const toggleLoader = () => {
        setLoader(!loader);
    };

    // Backend API call after rendering
    useEffect(() => {
        const fetchGames = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/sports/`);
                setGames(response.data);
            } catch (error) {
                console.error('Failed to fetch games:', error);
            }
        };
        fetchGames();
    }, []); // Empty dependency array to call once after initial render

    return (
        <>
            <Header />
            <Hero />
            <HomeAboutUs />
            <MobileAppFeature onOpenMembershipModal={toggleMembershipModal} />
            <StayInTouchCard onOpenStayInTouchModal={toggleStayInToucModal} />
            <CustomerOptions />
            <div className='homepage-lineargradient-bg-color'>
                <Brand />
                <Community />
                <Footer onOpenStayInTouchModal={toggleStayInToucModal} />
            </div>
            {isMembershipModalOpen ? (
                <JoinMembershipModel onClose={toggleMembershipModal} toggleLoader={toggleLoader} />
            ) : (
                ""
            )}
            {isStayInTouchModalOpen ? (
                <StayInTouchModel onClose={toggleStayInToucModal} toggleLoader={toggleLoader} games={games} />
            ) : (
                ""
            )}
            {loader && <p>Loading...</p>} {/* Show loader if loader is true */}
        </>
    );
};

export default Homepage;
