import React from 'react'
import TournamentBadge from "../../Asset/Badges/Tournament-Badge.svg"
import SocialBadge from "../../Asset/Badges/Social-Badge.svg"
import LeagueBadge from "../../Asset/Badges/League-Badge.svg"
import BootcampBadge from "../../Asset/Badges/Bootcamp-Badge.svg"

const Badges = ({EventType, size}) => {

    const getLogo = (type) => {
        switch (type) {
          case 'League':
            return LeagueBadge;
          case 'Tournament':
            return TournamentBadge;
          case 'Bootcamp':
            return BootcampBadge;
          case 'Social':
            return SocialBadge;
          default:
            return null;
        }
    };

    const Badge = getLogo(EventType); // Get the appropriate logo


  return (
    <div>
      {Badge ? (
        <img
          src={Badge}
          alt={EventType}
          // style={{ width: size.width, height: size.height }} // Use the size prop
        />
      ) : (
        <p>No logo available</p>
      )}
    </div>
  )
}

export default Badges
