import React from 'react'
import AppStoreImage from "../../../Asset/Membership/MembershipBenefitMobileApp/app-store-img.svg"
import PlayStoreImage from "../../../Asset/Membership/MembershipBenefitMobileApp/play-store-img.svg"
import mobileImage from "../../../Asset/Membership/MembershipBenefitMobileApp/membership-mobile-image.png"

const MembershipBenefitMobileApp = () => {
    return (
        <div id='membership-benefit-mobile-app-section' className='mt-24 mb-10 '>

            <div id='membership-benefit-content-container' className='flex md:flex-row flex-col justify-between items-center'>

                {/* left section text portion here */}
                <div id="membership-benefit-text-portion" className='flex flex-col gap-6 text-[var(--dark-blue)] font-medium md:w-[40%]'>
                    {/* Heading here */}
                    <div className='font-ClashGrotesk text-[2.3rem] md:text-[3.5rem] leading-[2.85rem] md:leading-[3.85rem] '>
                        Membership Benefits – Exclusively on Our Mobile App
                    </div>

                    {/* description here */}
                    <div className='md:text-xl text-base font-manrope'>
                        Want Your Membership Benefits? The app is your key— downloading our app is the only way to access all your benefits—don’t miss out!
                    </div>


                    {/* appstore and playstore images here */}

                    <div id='membership-benefit-apps-buttons' className='flex justify-center md:justify-normal   md:gap-4  md:w-full'>
                        <a href="https://www.apple.com/in/app-store/" rel="noreferrer" target='_blank'>
                            <img className=' w-[85%] mx-auto md:w-full' src={AppStoreImage} alt="App Store" />
                        </a>

                        <a href="https://play.google.com/store/games?hl=en" rel="noreferrer" target="_blank">
                            <img className=' w-[85%] mx-auto md:w-full' src={PlayStoreImage} alt="Play Store" />
                        </a>
                    </div>
                </div>


                {/* Right portion mobile image here */}
                <div id="membership-benefit-right-image" className='md:w-[50%] mt-20 md:mt-0 flex justify-center'>
                    <img className='nd:w-[95%]' src={mobileImage} alt="Membership Mobile App" />
                </div>
            </div>

        </div>
    )
}

export default MembershipBenefitMobileApp
