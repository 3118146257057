import React, { useEffect, useState } from "react";
import axios from "axios";
import { ClipLoader } from 'react-spinners';
import brownzePackageImg from "../../../Asset/Membership/MembershipPackages/bronze-membership-card.svg";
import silverPackageImg from "../../../Asset/Membership/MembershipPackages/silver-membership-card.svg";
import goldPackageImg from "../../../Asset/Membership/MembershipPackages/gold-membership-card.svg";
import tickArrow from "../../../Asset/Membership/MembershipPackages/tick-arrow.svg";
import BuyNowButton from "../../../Asset/Membership/MembershipPackages/BuyNow-Button.svg";
// import plans from "razorpay/dist/types/plans";

const MembershipPackages = ({ onOpenMembershipPackageModal }) => {
  const [planBronze, setPlanBronze] = useState([]); // State to store the response data
  const [planSilver, setPlanSilver] = useState([]); // State to store the response data
  const [planGold, setPlanGold] = useState([]); // State to store the response data
  const [loading, setLoading] = useState(false); // State to handle loading state
  const [error, setError] = useState(""); // State to handle errors

  useEffect(() => {
    const fetchPlans = async () => {
      setLoading(true); // Start loader
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/plans/?page=1&page_size=10`);
        setPlanBronze(response.data[0]); // Save the response data in state
        setPlanSilver(response.data[1]); // Save the response data in state
        setPlanGold(response.data[2]); // Save the response data in state

        console.log("Consoling responses one by one")
        console.log(response.data[0])
        console.log(response.data[1])
        console.log(response.data[2])
      } catch (err) {
        console.error(err);
        setError("Failed to fetch plans. Please try again later.");
      } finally {
        setLoading(false); // Stop loader
      }
    };

    fetchPlans(); // Call the function
  }, []);


  const handleOpenModalBronze = () => {
    const data = { PlanId: planBronze.PlanId, PlanTier: planBronze.PlanTier, Price: planBronze.Price }; // Example data
    onOpenMembershipPackageModal(data); // Send data to the parent
  };
  const handleOpenModalSilver = () => {
    const data = { PlanId: planSilver.PlanId, PlanTier: planSilver.PlanTier, Price: planSilver.Price }; // Example data
    onOpenMembershipPackageModal(data); // Send data to the parent
  };
  const handleOpenModalGold = () => {
    const data = { PlanId: planGold.PlanId, PlanTier: planGold.PlanTier, Price: planGold.Price }; // Example data
    onOpenMembershipPackageModal(data); // Send data to the parent
  };

  return (
    <div>
      <div id="membership-heading" className="pt-40 flex justify-center">
        <div className="font-ClashGrotesk text-[var(--dark-blue)] font-medium flex flex-col items-center">
          <h1 className="text-[2.5rem] md:text-[4.5rem]">Membership Tiers</h1>
          <p className="text-[1.75rem] md:text-[2.125rem]">Choose Your Adventure!</p>
        </div>
      </div>

      <div id="membership-packages" className="mt-12">

        {/* Show loader while loading */}
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <ClipLoader size={50} color={"#2D1066"} loading={loading} />
          </div>
        ) : error ? (
          <p className="text-red-500">{error} Sorry! Could not load packages.</p>
        ) : (
          <>


            {/* For medium devices view here */}
            <div className="md:flex hidden justify-center w-full md:gap-2">
              {/* Bronze Package */}
              <div
                className="w-full"
                style={{
                  backgroundImage: `url(${brownzePackageImg})`,
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div
                  id="bronze-content"
                  className="flex flex-col gap-3 mt-16 mb-40 text-[var(--dark-blue)] lg:ml-4 xl:ml-10 "
                >
                  <h1 className="text-[2rem] font-ClashGrotesk font-medium">{planBronze.PlanTier}</h1>
                  <p className="font-manrope font-[520] w-5/6 text-xl">
                    Perfect for those dipping their toes into a more active lifestyle.
                  </p>
                  <p className="font-ClashGrotesk font-medium text-[3.5rem]">₹{planBronze.Price}</p>
                  <div className="flex gap-2 items-center w-72 font-manrope mb-2">
                    <span className="flex-shrink-0">
                      <img src={tickArrow} alt="tick" />
                    </span>
                    <span className="font-bold text-xl">{planBronze.Credits} Credits</span>
                    <span className="font-semibold text-base text-[var(--light-purple-membership-package-card)]">
                      at ₹70/credit
                    </span>
                  </div>
                  <div className="flex gap-2 items-start justify-start w-72 font-manrope">
                    <span className="flex-shrink-0">
                      <img src={tickArrow} alt="tick" />
                    </span>
                    <span className="font-bold text-xl">50%</span>
                    <span className="font-semibold text-base text-[var(--light-purple-membership-package-card)]">
                      off non-member rates on events and play-pass!
                    </span>
                  </div>
                  <button onClick={handleOpenModalBronze} className="mt-4">
                    <img src={BuyNowButton} alt="Buy Button" />
                  </button>
                </div>
              </div>

              {/* Silver Package */}
              <div
                className="w-full"
                style={{
                  backgroundImage: `url(${silverPackageImg})`,
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div
                  id="silver-content"
                  className="flex flex-col gap-3 mt-16 mb-40 text-[var(--dark-blue)] lg:ml-4 xl:ml-10 "
                >
                  <h1 className="text-[2rem] font-ClashGrotesk font-medium">{planSilver.PlanTier}</h1>
                  <p className="font-manrope font-[520] w-5/6 text-xl">
                    Perfect for those dipping their toes into a more active lifestyle.
                  </p>
                  <p className="font-ClashGrotesk font-medium text-[3.5rem]">₹{planSilver.Price}</p>
                  <div className="flex gap-2 items-center w-72 font-manrope mb-2">
                    <span className="flex-shrink-0">
                      <img src={tickArrow} alt="tick" />
                    </span>
                    <span className="font-bold text-xl">{planSilver.Credits} Credits</span>
                    <span className="font-semibold text-base text-[var(--light-purple-membership-package-card)]">
                      at ₹70/credit
                    </span>
                  </div>
                  <div className="flex gap-2 items-start justify-start w-72 font-manrope">
                    <span className="flex-shrink-0">
                      <img src={tickArrow} alt="tick" />
                    </span>
                    <span className="font-bold text-xl">58%</span>
                    <span className="font-semibold text-base text-[var(--light-purple-membership-package-card)]">
                      off non-member rates on events and play-pass!
                    </span>
                  </div>
                  <button onClick={handleOpenModalSilver} className="mt-4">
                    <img src={BuyNowButton} alt="Buy Button" />
                  </button>
                </div>
              </div>

              {/* Gold Package */}
              <div
                className="w-full"
                style={{
                  backgroundImage: `url(${goldPackageImg})`,
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div
                  id="gold-content"
                  className="flex flex-col gap-3 mt-16 mb-40 text-[var(--dark-blue)] lg:ml-4 xl:ml-10 "
                >
                  <h1 className="text-[2rem] font-ClashGrotesk font-medium">{planGold.PlanTier}</h1>
                  <p className="font-manrope font-[520] w-5/6 text-xl">
                    Perfect for those dipping their toes into a more active lifestyle.
                  </p>
                  <p className="font-ClashGrotesk font-medium text-[3.5rem]">₹{planGold.Price}</p>
                  <div className="flex gap-2 items-center w-72 font-manrope mb-2">
                    <span className="flex-shrink-0">
                      <img src={tickArrow} alt="tick" />
                    </span>
                    <span className="font-bold text-xl">{planGold.Credits} Credits</span>
                    <span className="font-semibold text-base text-[var(--light-purple-membership-package-card)]">
                      at ₹70/credit
                    </span>
                  </div>
                  <div className="flex gap-2 items-start justify-start w-72 font-manrope">
                    <span className="flex-shrink-0">
                      <img src={tickArrow} alt="tick" />
                    </span>
                    <span className="font-bold text-xl">70%</span>
                    <span className="font-semibold text-base text-[var(--light-purple-membership-package-card)]">
                      off non-member rates on events and play-pass!
                    </span>
                  </div>
                  <button onClick={handleOpenModalGold} className="mt-4">
                    <img src={BuyNowButton} alt="Buy Button" />
                  </button>
                </div>
              </div>
            </div>



            {/* For mobile view here */}

            <div className="home-about-us-cards md:hidden carousel  w-[95vw]  md:mb-16 flex ">
              <div className="carousel-item  w-[80vw] md:w-[60vw] pl-2 ">

                {/* Bronze Package */}
                <div
                  className="w-full pl-3 flex items-center"
                  style={{
                    backgroundImage: `url(${brownzePackageImg})`,
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <div
                    id="bronze-content"
                    className="flex flex-col gap-3 mt-16 mb-40 text-[var(--dark-blue)] lg:ml-4 xl:ml-10 "
                  >
                    <h1 className="text-[1.70rem] font-ClashGrotesk font-medium">{planBronze.PlanTier}</h1>
                    <p className="font-manrope font-[520] w-5/6 text-[0.95rem]">
                      Perfect for those dipping their toes into a more active lifestyle.
                    </p>
                    <p className="font-ClashGrotesk font-medium text-[2.2rem]">₹{planBronze.Price}</p>
                    <div className="flex gap-2 items-center w-72 font-manrope mb-2">
                      <span className="flex-shrink-0">
                        <img src={tickArrow} alt="tick" />
                      </span>
                      <span className="font-bold text-[1.2rem]">{planBronze.Credits} Credits</span>
                      <span className="font-semibold text-[0.9rem] text-[var(--light-purple-membership-package-card)]">
                        at ₹70/credit
                      </span>
                    </div>
                    <div className="flex gap-2 items-start justify-start w-72 font-manrope">
                      <span className="flex-shrink-0">
                        <img src={tickArrow} alt="tick" />
                      </span>
                      <span className="font-bold text-[1.2rem]">50%</span>
                      <span className="font-semibold text-[0.9rem] text-[var(--light-purple-membership-package-card)]">
                        off non-member rates on events and play-pass!
                      </span>
                    </div>
                    <button onClick={handleOpenModalBronze} className="mt-4 w-28">
                      <img src={BuyNowButton} alt="Buy Button" />
                    </button>
                  </div>
                </div>

              </div>


              <div className="carousel-item   w-[80vw] md:w-[60vw] px-3">

                {/* Silver Package */}
                <div
                  className="w-full pl-3 flex items-center"
                  style={{
                    backgroundImage: `url(${silverPackageImg})`,
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <div
                    id="bronze-content"
                    className="flex flex-col gap-3 mt-16 mb-40 text-[var(--dark-blue)] lg:ml-4 xl:ml-10 "
                  >
                    <h1 className="text-[1.70rem] font-ClashGrotesk font-medium">{planSilver.PlanTier}</h1>
                    <p className="font-manrope font-[520] w-5/6 text-[0.95rem]">
                      Ideal for regulars aiming for the perfect balance of fitness and fun.
                    </p>
                    <p className="font-ClashGrotesk font-medium text-[2.2rem]">₹{planSilver.Price}</p>
                    <div className="flex gap-2 items-center w-72 font-manrope mb-2">
                      <span className="flex-shrink-0">
                        <img src={tickArrow} alt="tick" />
                      </span>
                      <span className="font-bold text-[1.2rem]">{planSilver.Credits} Credits</span>
                      <span className="font-semibold text-[0.9rem] text-[var(--light-purple-membership-package-card)]">
                        at ₹70/credit
                      </span>
                    </div>
                    <div className="flex gap-2 items-start justify-start w-72 font-manrope">
                      <span className="flex-shrink-0">
                        <img src={tickArrow} alt="tick" />
                      </span>
                      <span className="font-bold text-[1.2rem]">58%</span>
                      <span className="font-semibold text-[0.9rem] text-[var(--light-purple-membership-package-card)]">
                        off non-member rates on events and play-pass!
                      </span>
                    </div>
                    <button onClick={handleOpenModalBronze} className="mt-4 w-28">
                      <img src={BuyNowButton} alt="Buy Button" />
                    </button>
                  </div>
                </div>

              </div>


              <div className="carousel-item   w-[80vw] md:w-[60vw] pr-3">

                {/* Gold Package */}
                <div
                  className="w-full pl-3 flex items-center"
                  style={{
                    backgroundImage: `url(${goldPackageImg})`,
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <div
                    id="bronze-content"
                    className="flex flex-col gap-3 mt-16 mb-40 text-[var(--dark-blue)] lg:ml-4 xl:ml-10 "
                  >
                    <h1 className="text-[1.70rem] font-ClashGrotesk font-medium">{planGold.PlanTier}</h1>
                    <p className="font-manrope font-[520] w-5/6 text-[0.95rem]">
                      Crafted for the elite, the dedicated, the game-changers.
                    </p>
                    <p className="font-ClashGrotesk font-medium text-[2.2rem]">₹{planGold.Price}</p>
                    <div className="flex gap-2 items-center w-72 font-manrope mb-2">
                      <span className="flex-shrink-0">
                        <img src={tickArrow} alt="tick" />
                      </span>
                      <span className="font-bold text-[1.2rem]">{planGold.Credits} Credits</span>
                      <span className="font-semibold text-[0.9rem] text-[var(--light-purple-membership-package-card)]">
                        at ₹70/credit
                      </span>
                    </div>
                    <div className="flex gap-2 items-start justify-start w-72 font-manrope">
                      <span className="flex-shrink-0">
                        <img src={tickArrow} alt="tick" />
                      </span>
                      <span className="font-bold text-[1.2rem]">70%</span>
                      <span className="font-semibold text-[0.9rem] text-[var(--light-purple-membership-package-card)]">
                        off non-member rates on events and play-pass!
                      </span>
                    </div>
                    <button onClick={handleOpenModalBronze} className="mt-4 w-28">
                      <img src={BuyNowButton} alt="Buy Button" />
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </>

        )}

      </div>
    </div>
  );
};

export default MembershipPackages;
