import React from 'react'
import "./customerOptions.css";
import FindPlayCard from "../../../Asset/find-play.svg"
import FindCommunityCard from "../../../Asset/find-community.svg"
import FindCompetitionCard from "../../../Asset/find-competition.svg"

import greenb1 from "../../../Asset/CustomerOptionsHomepage/green-box-1.svg"
import greenb2 from "../../../Asset/CustomerOptionsHomepage/green-box-2.svg"
import greenb3 from "../../../Asset/CustomerOptionsHomepage/green-box-3.svg"

const customerOptions = () => {
    return (
        <div class="w-full  md:bg-[var(--light-yellow)] bg-[var(--very-light-yellow)] flex flex-col pb-10 mdpb-0 md:p-10 text-[var(--greenish-black)] ">

            {/* Upper Container */}
            <div className="customerOptions-upper-container flex flex-col md:flex-row justify-between px-4 md:px-0 mb-5 md:mb-10">
                <div className="two-columns md:w-1/2 w-[96%] text-left  text-[2.5rem] md:text-5xl lg:text-[3.5rem]  font-[490]">
                    <h1 className='font-ClashGrotesk mb-8 md:mb-0  md:ml-0 text-[var(--gray-black)] '>What's in it <br className='hidden md:block' />
                        for you</h1>
                </div>
                <div className="two-columns md:w-1/2  font-manrope text-base md:text-xl md:pl-4 font-[490] text-left">
                    <p>Imagine a weekend soirée, where you earn your cold ones by sweating it out first.  Prepare to rub shoulders with your future besties, unleash your competitive spirit, and forge bonds that could outlast the longest winning streak. Get ready to be part of the most sought-after sports social club ever assembled! </p>
                </div>
            </div>

            {/* Lower Container */}



            {/* For Mobile view only */}
            <div className="md:hidden carousel  w-full  md:mb-16 flex mt-5 md:mt-8  mb-16  md:px-0 font-[520] text-[var(--greenish-black)]">
                <div className="carousel-item  w-[55vw] md:w-[60vw]  pl-2">
                    <img className="w-full px-2  md:px-4" src={greenb1} alt="Find Play Card" />
                    {/* <div id="greenbox1" className='w-full h-full px-7 pt-4' style={{
                        backgroundImage: `url(${greenbox1})`,
                        backgroundSize: 'contain',        // Ensures the image covers the entire div
                        backgroundPosition: 'center',   // Centers the image
                        backgroundRepeat: 'no-repeat'   // Prevents the image from repeating
                    }}>
                        <h1 className='font-ClashGrotesk text-xl mb-2'>Find Play</h1>
                        <p className='font-manrope  text-sm'>Find your inner athlete and make play part of your every day.</p>
                    </div> */}
                </div>
                <div className="carousel-item  w-[55vw] md:w-[60vw] ">
                    <img className="w-full px-2  md:px-4" src={greenb2} alt="Find Community Card" />
                    {/* <div id="greenbox1" className='w-full h-full px-7 pt-4' style={{
                        backgroundImage: `url(${greenbox2})`,
                        backgroundSize: 'contain',        // Ensures the image covers the entire div
                        backgroundPosition: 'center',   // Centers the image
                        backgroundRepeat: 'no-repeat'   // Prevents the image from repeating
                    }}>
                        <h1 className='font-ClashGrotesk text-xl mb-2'>Find Community</h1>
                        <p className='font-manrope  text-sm'>Play your way to a healthier you. Join our community and discover the joy of fitness.</p>
                    </div> */}
                </div>
                <div className="carousel-item  w-[55vw] md:w-[60vw]  pr-2">
                    <img className="w-full px-2  md:px-4" src={greenb3} alt="Find Competition Card" />
                    {/* <div id="greenbox1" className='w-full h-full px-7 pt-4' style={{
                        backgroundImage: `url(${greenbox3})`,
                        backgroundSize: 'contain',        // Ensures the image covers the entire div
                        backgroundPosition: 'center',   // Centers the image
                        backgroundRepeat: 'no-repeat'   // Prevents the image from repeating
                    }}>
                        <h1 className='font-ClashGrotesk text-xl mb-2'>Find Competition</h1>
                        <p className='font-manrope  text-sm'>A dash of playful rivalry keeps the fun alive—engage, compete, and watch the excitement grow.</p>
                    </div> */}
                </div>
            </div>


            {/* for desktop and medium view only */}
            <div className="customerOptions-bottom-container w-full md:flex md:flex-nowrap items-center justify-between  mb-16 hidden ">

                <div className="customerOptions-card find-play w-[32%]">
                    <img className="w-full " src={FindPlayCard} alt="Find Play Card" />

                </div>
                <div className="customerOptions-card find-community w-[32%]">
                    <img className="w-full" src={FindCommunityCard} alt="Find Community Card" />
                </div>
                <div className="customerOptions-card find-competition w-[32%]">
                    <img className="w-full" src={FindCompetitionCard} alt="Find Competition Card" />
                </div>
            </div>


        </div>
    )
}

export default customerOptions
