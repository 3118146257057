import React, { useState } from 'react';
import MembershipPackages from './MembershipComponents/MembershipPackages';
import MembershipOffering from './MembershipComponents/MembershipOffering';
import MembershipBenefitMobileApp from './MembershipComponents/MembershipBenefitMobileApp';
import MembershipScheduleSection from './MembershipComponents/MembershipScheduleSection';
import MembershipPackageModal from './MembershipComponents/MembershipComponents/MembershipPackageModal';
import Header from '../Header/header';
import Footer from '../Footer/footer';

const MembershipPage = () => {
    const [isMembershipPackageModal, setIsMembershipPackageModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [modalData, setModalData] = useState(null); // State to hold data for the modal

    // Function to toggle modal visibility
    const toggleMembershipPackage = () => {
        setIsMembershipPackageModal(!isMembershipPackageModal);
    };

    // Function to toggle loader
    const toggleLoader = () => {
        setLoader(!loader);
    };

    // Function to handle data from MembershipPackages
    const handleOpenMembershipPackageModal = (data) => {
        setModalData(data); // Set data for the modal
        toggleMembershipPackage(); // Open the modal
    };

    return (
        <div id="membership-container">
            <Header />

            <div className="w-[94%] mx-auto">
                {/* Pass the function to handle modal data to MembershipPackages */}
                <MembershipPackages onOpenMembershipPackageModal={handleOpenMembershipPackageModal} />
                                
                <MembershipScheduleSection />

                <MembershipOffering />
                
                <MembershipBenefitMobileApp />
 
                

                {/* Pass modalData and other props to MembershipPackageModal */}
                {isMembershipPackageModal && (
                    <MembershipPackageModal
                        onClose={toggleMembershipPackage}
                        toggleLoader={toggleLoader}
                        modalData={modalData}
                    />
                )}
            </div>

            <Footer />
        </div>
    );
};

export default MembershipPage;
