import axios from 'axios';
import { useState, useEffect } from 'react';
import SquareBanner from "../../../Asset/net-gala-square-banner.svg"
import { Link } from 'react-router-dom';
import './joinMembershipModel.css'

const JoinMembershipModel = ({ onClose, toggleLoader }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        agreeTerms: false,
        subscribeUpdates: false
    });

    const [errors, setErrors] = useState({});

    // Check if all input fields are filled and checkbox is checked
    const isFormValid =
        formData.firstName.trim() !== '' &&
        formData.lastName.trim() !== '' &&
        formData.email.trim() !== '' &&
        formData.mobile.trim() !== '' &&
        formData.agreeTerms;


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const validateFields = () => {
        const newErrors = {};
        if (!formData.firstName) newErrors.firstName = true;
        if (!formData.lastName) newErrors.lastName = true;
        if (!validateEmail(formData.email)) newErrors.email = true;
        if (!validateMobile(formData.mobile)) newErrors.mobile = true;
        if (!formData.agreeTerms) newErrors.agreeTerms = true;

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const validateEmail = (email) => {
        // Basic email regex for validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateMobile = (mobile) => {
        // Validate that the mobile number is exactly 10 digits
        const mobileRegex = /^[0-9]{10}$/;
        return mobileRegex.test(mobile);
    };

    const registerClient = async (e) => {
        e.preventDefault();

        if (!validateFields()) return;

        try {
            toggleLoader();

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/prospect-users/`, {
                FirstName: formData.firstName,
                LastName: formData.lastName,
                MobileNumberExt: "+91",
                MobileNumber: formData.mobile,
                EmailAddress: formData.email,
                Notification: formData.subscribeUpdates,
                ProspectEntry: "Membership",
            },
                {
                    headers: {
                        "Content-Type": "application/json",
                        accept: "application/json",
                    },
                });

                if (response.status === 201) {
                    alert('Registration successful!');
                    onClose(); // Close only on success
                }
            } catch (error) {

                if(error.response.status ===400){
                    alert('Mobile Number already exists!')
                }
                else{
                    alert('Registration failed. Please try again.');
                console.error('Error:', error); // Log the error for debugging

                }

                
            } finally {
                toggleLoader(); // Ensure the loader toggles regardless of success or failure
            }
    };

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => document.body.style.overflow = 'unset';
    }, []);

    return (
        <div className="join-memebership-model fixed inset-0 bg-black bg-opacity-60 flex justify-center items-end md:items-center z-50">
            <div className="relative bg-[var(--background-light-gray)] md:bg-[var(--white)] rounded-t-3xl md:rounded-3xl shadow-lg w-[100%] md:max-w-5xl  md:max-h-[95vh] md:mx-4 flex flex-col md:flex-row p-3 md:p-0">
                <button onClick={onClose} className="absolute top-6 right-4 md:top-3 md:right-4 text-lg text-gray-500 hover:text-gray-700 bg-[#FFFFFFAB] w-7 h-8 rounded-md ">
                    &#x2715;
                </button>
                <div className="flex flex-col lg:flex-row items-center w-full ">
                    {/* Left Form Section */}
                    <div className="w-full lg:w-[75%] mb-6 ld:mt-10 lg:mb-6 ">
                        <div className=' w-[95%] md:w-[82%] mx-auto flex flex-col md:flex-row justify-between md:mb-3'>
                            <h2 className="text-[1.75rem] md:text-[2rem] leading-[3.5rem] md:mb-4 font-ClashGrotesk font-medium text-[var(--dark-blue)]">Join Membership Waitlist</h2>
                            <p className='font-manrope text-xs mt-auto md:mb-6 mb-3'><span className='text-red-500'>*</span>Required</p>
                        </div>

                        {/* Input fields here */}
                        <div>
                            <form className="md:space-y-4 space-y-2 w-full" onSubmit={registerClient}>
                                <div className='flex flex-col md:flex-row md:gap-4 gap-3  md:pl-14 '>
                                    <div className="flex flex-col  md:w-[15.5rem]">
                                        <input
                                            type="text"
                                            name="firstName"
                                            value={formData.firstName}
                                            onChange={handleChange}
                                            placeholder="First Name *"
                                            className={`p-3 border font-manrope font-semibold text-[var(--dark-blue)] text-[0.75rem] ${errors.firstName ? 'border-red-500' : 'border-gray-300'} rounded-lg bg-[var(--white)]`}
                                        />
                                    </div>


                                    <div className="flex flex-col md:w-[15.5rem]">
                                        <input
                                            type="text"
                                            name="lastName"
                                            value={formData.lastName}
                                            onChange={handleChange}
                                            placeholder="Last Name *"
                                            className={`p-3 border font-manrope font-semibold text-[var(--dark-blue)] text-[0.75rem] ${errors.lastName ? 'border-red-500' : 'border-gray-300'} rounded-lg bg-[var(--white)]`}
                                        />
                                    </div>
                                </div>

                                <div className='flex flex-col md:flex-row md:gap-4 gap-3  md:pl-14 mb-5'>
                                    <div className="flex flex-col md:w-[15.5rem]">
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            placeholder="Email Address *"
                                            className={`p-3 border font-manrope font-semibold text-[var(--dark-blue)] text-[0.75rem] ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-lg bg-[var(--white)]`}
                                        />
                                    </div>
                                    <div className="flex flex-col md:w-[15.5rem]">
                                        <input
                                            type="text"
                                            name="mobile"
                                            value={formData.mobile}
                                            onChange={handleChange}
                                            placeholder="Mobile Number *"
                                            className={`p-3 border font-manrope font-semibold text-[var(--dark-blue)] text-[0.75rem] ${errors.mobile ? 'border-red-500' : 'border-gray-300'} rounded-lg bg-[var(--white)]`}
                                        />
                                    </div>
                                </div>

                                {/* Checkboxes here */}
                                <div className='w-full flex flex-col gap-3  md:mb-0'>
                                    <div className="checkbox-container flex items-center md:pl-14 pt-5">
                                        <input type="checkbox" name="subscribeUpdates" checked={formData.subscribeUpdates} onChange={handleChange} className="custom-checkbox" id="join-newsletter" />
                                        <label htmlFor="join-newsletter" className="mr-2"></label>
                                        <span className='font-manrope font-semibold text-xs text-[var(--gray-brown)]'>Keep me updated on more events and news from Net Gala</span>
                                    </div>
                                    <div className="checkbox-container flex items-center md:pl-14 md:pb-5 pb-3">
                                        <input type="checkbox" name="agreeTerms" checked={formData.agreeTerms} onChange={handleChange} className="custom-checkbox" id="join-terms" />
                                        <label htmlFor="join-terms" className="mr-2" style={errors.agreeTerms ? { borderColor: 'rgba(239, 68, 68, 1)' } : {}}></label>
                                        <div className='font-manrope font-semibold text-xs text-[var(--gray-brown)]'>
                                            I agree to the{' '}
                                            <Link to="/terms">
                                                <u className="text-[var(--dark-blue)]">
                                                    Net Gala Terms of Service
                                                </u>
                                            </Link>
                                        </div>
                                    </div>
                                    <p className={` md:hidden ${errors.agreeTerms ? 'text-red-400 text-xs p-2 ml-2' : 'hidden'}`}>*Please agree to the Terms of Services</p>
                                </div>

                                <button type="submit" className={`w-full md:w-[67%] mx-auto md:mx-0 py-4 rounded-xl  md:ml-14 font-manrope font-semibold text-[1.1rem] ${!isFormValid? 'bg-[var(--button-disable-light-gray)] cursor-default' : 'bg-[var(--button-green)]' }`}>
                                    Register
                                </button>
                            </form>
                        </div>

                    </div>

                    {/* Right Image Section */}
                    <div className="w-full h-[12rem] md:w-full md:h-full lg:w-1/2 hidden md:flex justify-center items-center  object-cover ">
                        <img src={SquareBanner} alt="Net Gala" className="w-full h-full md:h-full object-cover rounded-2xl md:rounded-none md:rounded-r-2xl md:ml-auto" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JoinMembershipModel;
