import React from 'react'
import BackgroundImage from "../../../Asset/EventListing/event-listing-background.svg"
import bgImg from "../../../Asset/EventListing/eventlisting-bg-img.png"

const EventListingTextContent = () => {


    return (
        <div className='flex justify-center w-full ' >

            {/* for web view only */}
            <div className="eventListing-backgroundimg-webView w-full md:flex justify-start md:items-center pt-[8rem] md:flex-col gap-14 hidden text-[var(--dark-blue)] font-[510]" >
                <div id="event-listing-top-text" className=' flex flex-col md:flex-row w-[93%] md:w-[92%] md:justify-between gap-8 md:items-center  md:pt-24'>
                    <span className=' w-[80%] md:w-[40%] text-4xl md:text-6xl md:leading-[4.2rem] font-ClashGrotesk'>
                        Play your day,<br />
                        the Net Gala way
                    </span>

                    <span className='md:w-[50%] text-base md:text-xl font-manrope font-[520]'>
                        Net Gala is India’s first exclusive lifestyle community. We are on a mission to introduce sports in people’s lives and help them re-discover the joy of play to create a life outside the ordinary.
                    </span>
                </div>

                <div id="event-listing-second-text" className='md:w-[92%] flex justify-start font-ClashGrotesk text-[2rem]'>
                    <span>Let’s find something   <br /> sporty  and fun for you!</span>
                </div>

            </div>


            {/* Mobile View Here */}
            <div className=" w-full flex justify-center pt-36 md:hidden">
                <div id="event-listing-top-text" className='text-[var(--dark-blue)] font-[510] flex flex-col md:flex-row w-[93%] md:w-[90%] md:justify-between gap-8 md:items-center '>
                    <span className=' w-[80%] md:w-[40%] text-4xl md:text-6xl md:leading-[4.2rem] font-ClashGrotesk'>
                        Play your day,<br />
                        the Net Gala way
                    </span>

                    <span className='md:w-[50%] text-base md:text-xl font-manrope font-[520] text-[var(--eventListing-gray-text)]'>
                        Net Gala is India’s first exclusive lifestyle community. We are on a mission to introduce sports in people’s lives and help them re-discover the joy of play to create a life outside the ordinary.
                    </span>
                </div>

                <div id="event-listing-second-text">

                </div>

            </div>

        </div>
    )
}

export default EventListingTextContent
