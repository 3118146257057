import React, { useState, useEffect } from 'react';
import leftArrowVector from "../../../Asset/left-arrow-vector-carousel.svg";
import rightArrowVector from "../../../Asset/right-arrow-vector-carousel.svg";
import Axios from 'axios';


const StayInTouchCarousel = () => {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [eventImages, setEventImages] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0); // Track the current index


    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await Axios.get(`${process.env.REACT_APP_BASE_URL}/events/images/past`);
                setEvents(res.data);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchImages = async () => {
            if (events.length === 0) return;

            const imagePromises = events.map(async (event) => {
                try {
                    const res = await Axios.get(`${process.env.REACT_APP_BASE_URL}/files/${event.EventImageFileId}/download`, { responseType: "blob" });
                    const imageURL = URL.createObjectURL(res.data);
                    return { eventId: event.EventImageFileId, imageUrl: imageURL, title: event.EventTitle, eventType: event.EventType };
                } catch (error) {
                    console.error("Error fetching image for event", event.EventImageFileId, error);
                    return { eventId: event.EventImageFileId, imageUrl: "", title: event.EventTitle, eventType: event.EventType };
                }
            });

            const images = await Promise.all(imagePromises);
            setEventImages(images);
        };

        if (events.length > 0) {
            fetchImages();
        }
    }, [events]);

    // Move carousel with looping
    const moveCarousel = (direction) => {
        if (direction === 'left') {
            setCurrentIndex((prevIndex) => 
                prevIndex === 0 ? eventImages.length - 1 : prevIndex - 1
            );
        } else {
            setCurrentIndex((prevIndex) => 
                prevIndex === eventImages.length - 1 ? 0 : prevIndex + 1
            );
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className="md:flex justify-center items-center relative w-full">
            <button
                className="absolute top-1/2 md:-left-9 -left-4 transform -translate-y-1/2 text-white rounded-full text-xl w-1/3 z-30"
                onClick={() => moveCarousel('left')}
            >
                <img className='w-[2rem] md:w-16' src={leftArrowVector} alt="left arrow" />
            </button>

            <div className='md:w-full'>
                <p className="font-ClashGrotesk font-medium text-sm lg:text-xl w-full text-[var(--white)] bg-[var(--medium-purple)] p-6 md:pt-6 md:pb-20 md:pl-10 md:relative top-16 md:rounded-[2.5rem] temp-css-class pl-[1.5rem] py-[0.5rem] rounded-t-[2.5rem] rounded-r-[0.5rem] rounded-b-[0rem]">
                    Recent Net Gala Events
                </p>
                <div className="overflow-hidden w-full">
                    <div
                        className="flex w-full transition-transform ease-in-out duration-500"
                        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                    >
                        {eventImages.map((image) => (
                            <div key={image.eventId} className="flex-shrink-0 w-full">
                                <div className="flex justify-center items-center w-full relative">
                                    <img
                                        src={image.imageUrl}
                                        className="w-screen  h-auto max-h-36 md:max-h-64 z-20"
                                        alt={image.title}
                                    />
                                   
                                </div>
                                <p className="font-manrope text-base lg:text-[2rem] font-[620] w-full text-[var(--medium-purple)] bg-[var(--white)] p-6 md:pt-[4.8rem]  md:pb-9 md:pl-10 md:h-40 flex items-end -mt-0 md:-mt-4  md:relative bottom-16 md:bottom-14 md:rounded-b-[3.3rem] temp-css-class-2 py-[0.5rem] pl-[1.5rem] rounded-b-[2.5rem] rounded-t-[0rem]">
                                    {image.title}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <button
                className="absolute top-1/2 md:-right-9 -right-4 transform -translate-y-1/2 text-white rounded-full text-xl z-30"
                onClick={() => moveCarousel('right')}
            >
                <img className='w-[2rem] md:w-16' src={rightArrowVector} alt="right arrow" />
            </button>
        </div>
    );
};

export default StayInTouchCarousel;
