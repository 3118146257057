import React from "react";
import Header from "../Header/header";
import Footer from "../Footer/footer";
import './privacy.css'

const Privacy = () => {
  return (
    <div>
      <Header></Header>

      <div className="w-full pt-44 ">
        {/*  First portion of top area */}
        <div className="privacy-heading  w-full md:w-4/5 mx-auto text-center  mb-20 font-medium">
          <h1 className="xl:text-5xl md:text-4xl text-2xl font-ClashGrotesk text-[var(--medium-purple)] md:text-left ">
            Privacy Policy
          </h1>
        </div>

        <div className="privacy-description flex flex-col md:w-4/5 w-[90%] text-justify mx-auto font-manrope md:text-lg text-sm bg-[--white] p-4 rounded-2xl text-[var(--dark-blue)] font-semibold mb-20 gap-4">
          <p>
            Net Gala Sports Pvt. Ltd. (hereinafter referred to as “Net Gala”,
            “NG”, “we”, “us”, “our” and terms of similar meaning) owns and
            operates the website (
            <a
              className="text-[var(--reddish-brown)] md:hover:text-xl md:hover:text-[var(--dark-blue)] "
              href="https://thenetgala.in"
              target="_blank" rel="noreferrer"
            >
              https://thenetgala.in/
            </a>
            ) and domain name, and any other linked pages, features, content,
            mobile applications, or any other services we offer from time to
            time in connection therewith (collectively referred to as the
            “Platform”), to provide various sports and fitness sessions, sports
            coaching, sporting events, different avenues such as social events,
            sports tournaments and leagues etc., for young adults to network, or
            simply find support in one another, personally and professionally,
            either through Virtual Sessions or Physical Sessions.
          </p>

          <p>
            By accessing the Platform (either as a registered user or a
            visitor/viewer) and/or by using our Services (as defined in our
            Terms of Use) in any manner, you (hereinafter referred to as “you”,
            “your”, “User”, as applicable) expressly consent to our collection,
            storage, use and disclosure of the Information (as defined
            hereunder) in accordance with the terms of this Privacy Policy, as
            amended from time to time (“Privacy Policy”).
          </p>

          <p>
            This Privacy Policy forms part and parcel of our Terms and
            Conditions available at (
            <a
              className="text-[var(--reddish-brown)] md:hover:text-xl md:hover:text-[var(--dark-blue)] "
              href="https://thenetgala.in"
              target="_blank" rel="noreferrer"
            >
              https://thenetgala.in/
            </a>
            ) (“Terms”) and is included therein by this reference. The
            capitalized terms which have been used herein but are not
            specifically defined shall have the same meaning as attributed to
            such term in the Terms. In the event of any inconsistency between
            the Terms and this Privacy Policy, the Privacy Policy shall prevail
            solely in respect of the content contained herein and not in respect
            of any other clause, term or provision contained in the Terms. We
            will not share any User information with any third party without
            your consent, except as outlined in this Privacy Policy or as
            permitted by law.
          </p>

          <p>
            As we update, improve and expand the Platform, this Privacy Policy
            may change at our sole discretion without notice, so please refer to
            the Privacy Policy periodically as you will be deemed to be governed
            by the terms of such modified Privacy Policy, as it comes into
            effect from time to time.
          </p>

          <p>
            This document is an electronic record in terms of Information
            Technology Act, 2000 and rules there under as applicable and the
            amended provisions pertaining to electronic records in various
            statutes as amended by the Information Technology Act, 2000
            (together with any statutory amendments or modifications thereof).
            This electronic record is generated by a computer system and does
            not require any physical or digital signatures.
          </p>
        </div>
      </div>

      {/* Second Portion of privacy : Types of Information collected */}
      <div className="privacy-heading  w-full md:w-4/5 mx-auto text-center pt-10 mb-12 font-medium">
        <h1 className="xl:text-4xl md:text-3xl text-xl font-ClashGrotesk text-[var(--medium-purple)] md:text-left ">

          Types Of Information Collected
        </h1>
      </div>

      <div className="privacy-description flex flex-col md:w-4/5 w-[90%] text-justify mx-auto font-manrope md:text-lg text-sm bg-[--white] p-4 rounded-2xl text-[var(--dark-blue)] font-semibold mb-20 gap-4">
        <p>
          To access/utilise the Platform, the Users are required to provide
          certain personal, non-personal and other information, as indicated
          below (such information and correspondences hereinafter collectively
          referred to as “Information”):
        </p>

        <ul className="list-decimal list-outside pl-2 md:pl-4 flex flex-col gap-4">
          {/* First point of the list */}
          <li>
            Personal Information: In order for you to access/utilize the
            Platform and/or Services Provided on the Platform and/or as part of
            the registration process on the Platform, we may require you to
            provide us with certain information that personally identifies you
            (“Personal Information”). Personal Information shall include but not
            be limited to the following categories of information:
          </li>

          <ul className="privacy-roman-style-list list-disc list-outside pl-2 md:pl-4 md:text-base text-sm ">
            <li>
              Name, age, email address, password, country, city, contact details
              and company/organization that you are associated with, when you
              register for availing various services (“Services”) through the
              Platform;
            </li>
            <li>
              Information such as gender, birth date, sports preferences,
              skill-level, location and other health related information
              provided to us on the Platform during registration or for availing
              the Services on the Platform;
            </li>
            <li>
              We may collect your Personal Information such as name, age,
              contact details, preferences, etc. through surveys and forms, when
              you choose to participate in these surveys etc.;
            </li>
            <li>
              When you communicate with Net Gala or use the Platform to
              communicate with other Users (such as business partners/agents),
              we collect information about your communication and any
              information you choose to provide;
            </li>
            <li>
              To the extent permitted by law, Net Gala may record and monitor
              your communications with us to ensure compliance with our legal
              and regulatory obligations and our internal policies. This may
              include the recording of telephone conversations; and
            </li>
            <li>
              If you choose to sign in with your social media account to access
              the Platform, or otherwise connect your social media account with
              the Services of the Platform, you consent to our collection,
              storage, and use, in accordance with this Privacy Policy, of the
              information that you make available to us through the social media
              interface. Please see your social media provider's privacy policy
              and help centre for more information about how they share
              information when you choose to connect your account.
            </li>
          </ul>

          <p className="pt-2">
            Please note that the above list is not an exhaustive list and is
            only an illustrative list and is without prejudice to our right to
            seek further Personal Information, as may be required for the
            provision of the Services to you and under applicable laws.
          </p>
          <p className="pt-2">
            We will collect Personal Information about you only as part of a
            voluntary registration process, online survey or any combination
            thereof. You can always opt not to disclose Information to us, but
            keep in mind some Information may be needed to register with us on
            the Platform or to take advantage of some of our special features /
            Services.
          </p>
          <p className="pt-2">
            Any Personal Information provided by you will not be considered as
            sensitive if it is freely available and/or accessible in the public
            domain. Further, any comments, messages, blogs, etc., posted/
            communicated by you on the public or comments sections of the
            Platform becomes published content and is not considered personally
            identifiable information subject to this Privacy Policy. We will not
            be responsible for the accuracy or filtration of any of the content
            posted on our Platform by any User, however, we will be entitled to
            remove any content posted by any User on our Platform which we, in
            our sole discretion consider offensive, discriminatory, racist,
            defamatory or inappropriate in nature.
          </p>

          {/* Second Point of the List */}

          <li>
            Non-Personal Information: When you visit the Platform, we may
            collect certain non-personal information such as your internet
            protocol address, operating system, browser type, internet service
            provider, information about the pages on the Platform you
            visit/access, the links you click on the Platform, the number of
            times you access the page and any such browsing information. This
            type of information does not identify you personally. We may also
            collect information about the Services that you use and how you use
            them, including log-in information and location information, when
            you are a user of the Services through the Platform.
          </li>

          {/* Third Point of the List */}

          <li>
            Automatic Information: To improve the responsiveness of the Platform
            for you, we may use “cookies”, or similar electronic tools to
            collect information to understand the User’s individual interests.
            These server logs may include information such as your web request,
            Internet Protocol address, browser type, browser language, the date
            and time of your request and one or more cookies that may uniquely
            identify your browser. We use cookies primarily for User
            authentication but may also use them to improve the quality of our
            Service by storing User preferences and tracking User trends.
          </li>

          {/* Fourth Point of the List */}

          <li>
            Application: When you use the Platform through your mobile, we may
            receive information about your location and your mobile device,
            including a unique identifier number for your device. We may use
            this information to provide you with suggestions in respect of
            Services, if required.
          </li>

          {/* Fifth Point of the List */}

          <li>
            User communications: When you send emails or other communications to
            us, we may retain those communications in order to process your
            inquiries, respond to your requests and improve our Services to you.
          </li>
        </ul>

        <p>
          In case you choose to decline to submit any Information, we may not be
          able to provide you access to the Platform or to our Services or
          certain aspects of the Platform or our Services. We will not be liable
          and/or responsible for the denial of providing access to the Platform
          to you on account of inadequacy of Information provided by you.s
        </p>
        <p>
          When you register with the Platform, we may contact you from time to
          time about updating of Information, to provide you such features that
          we believe may benefit/interest you.
        </p>
      </div>

      {/* Third Portion of privacy : USE OF INFORMATION */}
      <div className="privacy-heading  w-full md:w-4/5 mx-auto text-center pt-10 mb-12 font-medium">
        <h1 className="xl:text-4xl md:text-3xl text-xl font-ClashGrotesk text-[var(--medium-purple)] md:text-left ">

          Use Of Information
        </h1>
      </div>

      <div className="privacy-description flex flex-col md:w-4/5 w-[90%] text-justify mx-auto font-manrope md:text-lg text-sm bg-[--white] p-4 rounded-2xl text-[var(--dark-blue)] font-semibold mb-20 gap-4">
        <p>
          We may process the Information you provide in any one or more of the
          following manners, in accordance with this Privacy Policy and as
          permitted by applicable laws:
        </p>

        <ul className=" md:text-base text-sm list-decimal list-outside pl-2 md:pl-4 flex flex-col gap-4">
          {/* First point of the list */}
          <li>
            To provide our Services to you including to send you offer alerts,
            calendar alerts, relevant search results, recommended
            sessions/events/games and such other similar communications;
          </li>

          {/* Second Point of the List */}

          <li>
            To process and fulfil your request for Services, to provide
            personalized features to you on the Platform and promotional offers
            to you through the Platform and other channels;
          </li>

          {/* Third Point of the List */}

          <li>
            We may disclose your Personal Information to Service Professionals
            (as defined in the Terms) and/or other registered business partners
            (as applicable). We or our Service Professionals/registered business
            partners may present marketing material and customised messages to
            you, when necessary to provide Services requested by you;
          </li>

          {/* Fourth Point of the List */}

          <li>
            To improve our Services and offerings, to contact you as a survey
            respondent;
          </li>

          {/* Fifth Point of the List */}

          <li>
            To notify you if you win any tournament/league; and to send you
            promotional materials from its contest sponsors or advertisers;
          </li>

          {/* Sixth Point of the List */}

          <li>To enforce our Terms;</li>

          {/* Seventh Point of the List */}

          <li>
            To protect the security or integrity of the Platform, our business,
            Services, and other users;
          </li>

          {/* Eighth Point of the List */}

          <li>
            To publish any testimonials or reviews that you may have provided on
            the Platform;
          </li>

          {/* Ninth Point of the List */}

          <li>
            Preventing, detecting, investigating and taking action against
            crimes (including but not limited to fraud and other financial
            crimes), any other illegal activities, suspected fraud, or
            violations of Terms in any jurisdiction;
          </li>

          {/* Tenth Point of the List */}

          <li>
            To the extent required for identity verification, government
            sanctions screening and due diligence checks;
          </li>

          {/* Eleventh Point of the List */}

          <li>
            Establishing, exercising or defending legal rights in connection
            with legal proceedings (including any prospective legal proceedings)
            and seeking professional or legal advice in relation to such legal
            proceedings; and
          </li>

          {/* Twelvth Point of the List */}

          <li>
            To serve various promotional and advertising materials to you via
            display advertisements through the Google Ad network on third party
            websites. You can opt out of Google Analytics for Display
            Advertising and customize Google Display network ads using the Ads
            Preferences Manager.
          </li>
        </ul>
      </div>

      {/* Fourth Portion of privacy : DISCLOSURE OF INFORMATION OBTAINED */}
      <div className="privacy-heading  w-full md:w-4/5 mx-auto text-center pt-10 mb-12 font-medium">
        <h1 className="xl:text-4xl md:text-3xl text-xl font-ClashGrotesk text-[var(--medium-purple)] md:text-left ">

          Disclosure of Information Obtained
        </h1>
      </div>

      <div className="privacy-description flex flex-col md:w-4/5 w-[90%] text-justify mx-auto font-manrope md:text-lg text-sm bg-[--white] p-4 rounded-2xl text-[var(--dark-blue)] font-semibold mb-20 gap-4">
        <ul className=" md:text-base text-sm list-decimal list-outside pl-2 md:pl-4 flex flex-col gap-4">
          {/* First point of the list */}
          <li>
            We will not use your financial information for any purpose other
            than to complete a transaction with you.
          </li>
          {/* Second point of the list */}
          <li>
            Except as expressly stated herein, we do not rent, sell or share
            your Personal Information and will not disclose any of your Personal
            Information to third parties. In cases where we have your permission
            to provide Services (as defined in the Terms) that you have
            requested and such Information is necessary to provide these
            Services, the Information may be shared with our business associates
            including the trainer/fitness coaches, business partners and other
            third party service suppliers. We may, however, share consumer
            information on an aggregate with our partners or third parties where
            we deem necessary. In addition, we may use this Information for
            promotional offers.
          </li>
          {/* third point of the list */}
          <li>
            We may disclose Information, in good faith, wherein the access, use,
            preservation or disclosure of such Information, including
            preservation of transaction history is reasonably necessary to (i)
            satisfy any applicable law, regulation, legal process or enforceable
            governmental request; (ii) enforce applicable Terms, including
            investigation of potential violations thereof; (iii) detect,
            prevent, or otherwise address fraud, security or technical issues;
            (iv) protect against imminent harm to our rights, property or
            safety, our users or the public as required or permitted by law; or
            (v) to defend against legal claims; special circumstances such as
            compliance with subpoenas, court orders, requests/order from legal
            authorities or law enforcement agencies requiring such disclosure.
          </li>
          {/* fourth point of the list */}
          <li>
            Additionally, in the event of an audit, reorganization, merger,
            sale, joint venture, assignment, transfer or other disposition of
            all or any portion of our business, assets or stock (including in
            connection with any bankruptcy or similar proceedings), we may
            share/transfer/assign the Information we have collected to the
            relevant third party.
          </li>
        </ul>
      </div>

      {/* Fifty Portion of privacy : DATA RETENTION */}
      <div className="privacy-heading  w-full md:w-4/5 mx-auto text-center pt-10 mb-12 font-medium">
        <h1 className="xl:text-4xl md:text-3xl text-xl font-ClashGrotesk text-[var(--medium-purple)] md:text-left ">

          Data Retention
        </h1>
      </div>

      <div className="privacy-description flex flex-col md:w-4/5 w-[90%] text-justify mx-auto font-manrope md:text-lg text-sm bg-[--white] p-4 rounded-2xl text-[var(--dark-blue)] font-semibold mb-20 gap-4">
        <p>
          We shall be entitled to retain your Information for our records for
          such period of time as may be required by applicable laws, after the
          deletion of your Account on the Platform and/or completion of Services
          and such Information shall be used by us only in consonance with this
          Privacy Policy, including for the purpose of compliance with legal
          obligations, resolving of disputes and enforcing any agreements
          executed between us.
        </p>
      </div>

      {/* Sixth Portion of privacy : ACCESSING AND UPDATING YOUR INFORMATION */}
      <div className="privacy-heading  w-full md:w-4/5 mx-auto text-center pt-10 mb-12 font-medium">
        <h1 className="xl:text-4xl md:text-3xl text-xl font-ClashGrotesk text-[var(--medium-purple)] md:text-left ">

          Accessing And Updating Your Information
        </h1>
      </div>

      <div className="privacy-description flex flex-col md:w-4/5 w-[90%] text-justify mx-auto font-manrope md:text-lg text-sm bg-[--white] p-4 rounded-2xl text-[var(--dark-blue)] font-semibold mb-20 gap-4">
        <p>
          As a registered User of the Platform, you can update or modify the
          Personal Information you have included in your profile or change your
          username by logging in and accessing your Account. Though you may
          delete your Account, you acknowledge that it may be impossible to
          remove your Account without some residual information being retained
          by the Platform in accordance with this Privacy Policy.
        </p>
      </div>

      {/* Seventh Portion of privacy : SECURITY */}
      <div className="privacy-heading  w-full md:w-4/5 mx-auto text-center pt-10 mb-12 font-medium">
        <h1 className="xl:text-4xl md:text-3xl text-xl font-ClashGrotesk text-[var(--medium-purple)] md:text-left ">

          Security
        </h1>
      </div>

      <div className="privacy-description flex flex-col md:w-4/5 w-[90%] text-justify mx-auto font-manrope md:text-lg text-sm bg-[--white] p-4 rounded-2xl text-[var(--dark-blue)] font-semibold mb-20 gap-4">
        <p>
          We use reasonable security measures to protect Information from
          unauthorized access, maintain data accuracy and help ensure the
          appropriate use/disclosure of Information. We host the Platform in a
          secure server environment that uses firewalls and other advanced
          technology to prevent interference or access from outside intruders.
          Although we will endeavour to safeguard the confidentiality of your
          Information, transmissions made by means of the Internet cannot be
          made absolutely secure. By using this Platform, you agree that we will
          have no liability for disclosure of your Information due to errors in
          transmission or unauthorized acts of third parties.
        </p>
        <p>
          You are responsible for maintaining the security and confidentiality
          of your Account, user ids and passwords while accessing the Platform
          as a User. You acknowledge that neither us nor any of our
          representatives shall be liable to you under any circumstances for any
          direct, indirect, punitive, incidental, special or consequential
          damages that result from or arise out of, or a breach or compromise of
          registration of your Account on the Platform and/or your ability or
          inability to use such Account.
        </p>
        <p>
          We use third-party payment processors (“Payment Processors”) for any
          payments made on the Platform. The processing of payments will be
          subject to the terms, conditions and privacy policies of the Payment
          Processors in addition to the Terms. You acknowledge that neither us
          nor any of its representatives shall be liable to you under any
          circumstances for any direct, indirect, punitive, incidental, special
          or consequential damages that result from or arise out of your use of
          the Payment Processors, your payment through the Payment Processors or
          your ability or inability to complete the payment.
        </p>
      </div>

      {/* Eighth Portion of privacy : LINKS TO THIRD PARTY SITES */}
      <div className="privacy-heading  w-full md:w-4/5 mx-auto text-center pt-10 mb-12 font-medium">
        <h1 className="xl:text-4xl md:text-3xl text-xl font-ClashGrotesk text-[var(--medium-purple)] md:text-left ">

          Links To Third Party Sites
        </h1>
      </div>

      <div className="privacy-description flex flex-col md:w-4/5 w-[90%] text-justify mx-auto font-manrope md:text-lg text-sm bg-[--white] p-4 rounded-2xl text-[var(--dark-blue)] font-semibold mb-20 gap-4">
        <p>
          The Platform may include links to third party applications or websites
          (“Third Party Sites”). Such Third Party Sites are governed by their
          respective privacy policies, which are beyond our control. This
          Privacy Policy is applicable exclusively to the Platform. Once you
          leave our Platform, use of any information you provide is governed by
          the privacy policy of the operator of the Third Party Site you are
          visiting and we recommend you to review the respective privacy
          policies of such Third Party Sites with whom you interact. That policy
          may differ from ours. If you cannot find the privacy policy of any of
          these Third Party Sites, you should contact the relevant Third Party
          Site directly for more information.
        </p>
        <p>
          We do not provide any Personal Information or personally identifiable
          information to third party websites / advertisers / ad-servers without
          your consent.
        </p>
      </div>

      {/* Ninth Portion of privacy : GRIEVANCE REDRESSAL OFFICER */}
      <div className="privacy-heading  w-full md:w-4/5 mx-auto text-center pt-10 mb-12 font-medium">
        <h1 className="xl:text-4xl md:text-3xl text-xl font-ClashGrotesk text-[var(--medium-purple)] md:text-left ">

          Grievance Redressal Officer
        </h1>
      </div>

      <div className="privacy-description flex flex-col md:w-4/5 w-[90%] text-justify mx-auto font-manrope md:text-lg text-sm bg-[--white] p-4 rounded-2xl text-[var(--dark-blue)] font-semibold mb-20 gap-4">
        <p>
          Any complaints, abuse or concerns with regards to content and/or
          comment and/or breach of this Privacy Policy shall be immediately
          informed to the designated Grievance Redressal Officer as mentioned
          below via writing or through email:
        </p>

        <div className="flex flex-col gap-3">

          <p>Details of the Grievance Redressal Officer:</p>
          <p>Name: Shayoni Nair</p>
          <p>Designation: Founder</p>
          <p>Email: <a className="text-[var(--reddish-brown)]  " href="mailto:hello@thenetgala.in">hello@thenetgala.in</a></p>
          <p>Phone: +91-9871438591</p>
          <p>Address: Villa 24, Bougainvilla Drive, Karma Lakelands, Sector 80, Gurgaon, Haryana - 122004.</p>

        </div>
      </div>

      <Footer></Footer>
    </div>
  );
};

export default Privacy;
